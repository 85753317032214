karmads.load = (function(d, w) {

    function log(msg, level, docsLink) {
        level = level || 'log';
        var c = w.console, msg = '%c--KARMA ' + level.toUpperCase() + ((level === 'warn') ? 'ING' : '') + ': ' + msg;
        if (docsLink) {
            msg += ' http://karma.mdpcdn.com/docs/dev/#' + docsLink;
        }
        if (c) {
            switch (level) {
            case 'error':
                c.error(msg, 'font-size:14px;color:red');
                break;
            case 'warn':
                c.warn(msg, 'font-size:14px;color:darkgoldenrod');
                break;
            default:
                c.log(msg, logStyles);
                break;
            }
        }
    }

    function init(mode) {
        w.karma = w.karma || {};

        let queryString = w.location.search,
            urlEnvParam = queryString.match(/adTestEnv=([^&]+)/i),
            useJsMinParam = queryString.match(/adTestMin=([^&]+)/i),
            karmaCore,
            node,
            fileEnv = (urlEnvParam !== null && urlEnvParam.length > 1) ? urlEnvParam[1] : 'www',
            isStaging = (fileEnv !== 'www'),
            isLocal = (fileEnv === 'local'),
            useJsMin = (useJsMinParam !== null) ? (useJsMinParam[1] === 'true') : !isStaging,
            fileName = (mode == 'header') ? 'header' : 'footer',
            scriptPath;

        log('This is a deprecated KARMA filename. You should upgrade to KARMA3.', 'warn', 'karma-3-migration-guide');

        karmaCore = d.createElement('script');
        karmaCore.src = 'https://' + (isStaging ? fileEnv + '.' : '') + 'karma.mdpcdn.com' + (isLocal ? ':9999' : '') +  '/service/' + (useJsMin ? 'js-min' : 'js') + '/karma.' + fileName + '.js';
        node = d.getElementsByTagName('script')[0];
        node.parentNode.insertBefore(karmaCore, node);

    }

    return {
        init: init
    };

})(document, window);